import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Auftragsuebersicht from '../../../components/ProductDetails/netstorsys/auftragsuebersicht'
import Seo from "../../../components/App/Seo"

const AuftragsuebersichtPage = () => {
  return (
    <Layout>
      <Seo title={"Intralogistik Auftragsmanagement zum optimierten Picking"}
           description={"Aufträge sind die Basis für externe Transaktionen. " +
           "Sei es eine eCommerce-Bestellung des bestehenden " +
           "Shops oder ein Auftrag für die Produktfertigung"}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Auftragsuebersicht />
      <Footer />
    </Layout>
  );
}

export default AuftragsuebersichtPage
