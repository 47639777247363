import React from "react"
import headerImage from "../../../assets/images/products/netstorsys/auftragsuebersicht_header.svg"
import orderOverview from "../../../assets/images/products/netstorsys/wm-orderoverview.svg"
import clickCollectImage from "../../../assets/images/products/netstorsys/clickcollect.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"
import { StaticImage } from "gatsby-plugin-image"
import {Link} from 'gatsby'
import FeatureSection from "../../App/FeatureSection"

const Auftragsuebersicht = () => {
  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={headerImage} alt="about" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETSTORSYS</span>
                <h1 className={"pt-3"}>Auftragsübersicht </h1>
                <p>
                  Aufträge sind die Basis für externe Aufträge. Sei es eine eCommerce-Bestellung des bestehenden Shops
                  oder ein Auftrag für die Produktfertigung. Hierbei unterstützt NETSTORSYS verschiedene Arten, wie
                  Aufträge in das System gelangen. Die HTTP / REST basierte Schnittstelle ermöglicht hierbei eine
                  einfache Möglichkeit der Integration mit anderen Systemen. Auch andere Schnittstellen sind kein
                  Problem - wenn das externe System Daten exportieren kann, kann NETSTORSYS diese auch importieren.
                </p>
                <p>
                  Die Aufträge stellen damit die direkte Schnittstelle zwischen bestehenden Systemen und NETSTORSYS dar.
                  Außerdem bieten sie die Möglichkeit direkt einzusehen, ob der Lagerbestand für den Auftrag ausreichend
                  ist. Eine manuelle Anpassung ist immer direkt möglich.
                </p>


                <WichtigeMerkmale items={[
                  "Überprüfung der Auftragslage",
                  "Wiedereinlagerung",
                  "Warenprüfung",
                  "Erfüllbarkeit der Bestellung durch vorhandenen Bestand",
                  "Schnittstelle zwischen NETSTORSYS und Vorsystemen",
                  "schneller Prozess",
                ]} image={<img src={orderOverview}
                               alt="Auftragsüberisicht Merkmale"
                               className={"ml-lg-4 ml-0"} />}
                />

                <p>
                  Für kleine Umgebungen ohne Vorsystem gibt es sogar die Möglichkeit Aufträge über eine Bildschirmmaske
                  in NETSTORSYS selbst zu erfassen. Diese Auftragserfassung ist jedoch lediglich logistischer Natur und
                  bietet keine ERP Funktionalitäten wie Faktura, Mahnungen oder ähnliche Features.
                </p>

                <h3>Relevantes aus dem Lexikon</h3>
                <LexikonBadgesRandomSection product={"Netstorsys"}/>
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
      <FeatureSection backgroundColorClass={"bg-f1f8fb"}
                      imagePosition={"left"}
                      image={<StaticImage
                        src="../../../assets/images/products/netstorsys/screenshots/website/ns_orders_framed.png"
                        alt="Auftragsübersicht Screenshot"
                        placeholder="blurred"
                        width={600}
                      />}
                      header={"Artikelmanagement"}
                      textBlock1={"In der Auftragsübersicht kann direkt über die Oberfläche von NETSTORSYS der Status eingesehen werden. "+
                        "Durch die Anbindung an bestehende Vorsystemen sind diese immer aktuell und spiegeln somit auch dessen "+
                        "Stand wieder. "+
                        "Im Detail ist direkt ersichtlich, welche Positionen eines Auftrags genügend Bestand aufweisen und welche "+
                        "nicht erfüllbar sind. Angepasst an Ihre Bedürfnisse lassen sich mit diesen Kennzahlen beispielsweise "+
                        "bestimmte Prozesse starten oder Warnungen auslösen. Somit können Sie zeitnah auf Bestandsprobleme "+
                        "reagieren oder diese sogar automatisch lösen lassen."}
                      textBlock2={<span>Die Abwicklung der Aufträge, oder auch nur Teilaufträge, erfolgt transparent
                über <Link to={"/lagerverwaltung/netstorsys/warenbewegungen/"}>Transportaufträge</Link>. In diesem Prozess
                werden die benötigten Bestände im System fest reserviert
                und mit dem Auftrag verknüpft. Über anpassbare Strategien kann NETSTORSYS Artikel in bestimmten
                Bereichen des Lagers zuerst reservieren oder beispielsweise nach Ablaufdatum, Charge oder Ähnlichem
                sortiert vorgehen.</span>}
                      textBlock3={<span>Die Transportaufträge sind wiederum direkt in das Batch System integriert und erlauben eine
                Positionsgenaue Verfolgung des Auftragsstatus. Darüberhinaus kann die Reihenfolge der Abarbeitung von
                Aufträgen manuell festgelegt und somit auch nach Priorität geordnet werden oder nach bestimmten Regeln
                automatisch "verbatchen". Eine Zuordnung von Aufträgen zu definierten Mitarbeitergruppen ist auch
                möglich. Damit schafft NETSTORSYS eine flexible Bearbeitung von Aufträgen, die sich an Ihre bestehenden
                Prozesse anpasst.</span>}
      />

      <FeatureSection backgroundColorClass={"bg-23173a"}
                      imagePosition={"right"}
                      image={<img src={clickCollectImage} alt={"Click & Collect"} />}
                      header={"Click & Collect"}
                      textBlock1={"In der Auftragsübersicht kann direkt über die Oberfläche von NETSTORSYS der Status eingesehen werden. "+
                      "Durch die Anbindung an bestehende Vorsystemen sind diese immer aktuell und spiegeln somit auch dessen "+
                      "Stand wieder. "+
                      "Im Detail ist direkt ersichtlich, welche Positionen eines Auftrags genügend Bestand aufweisen und welche "+
                      "nicht erfüllbar sind. Angepasst an Ihre Bedürfnisse lassen sich mit diesen Kennzahlen beispielsweise "+
                      "bestimmte Prozesse starten oder Warnungen auslösen. Somit können Sie zeitnah auf Bestandsprobleme "+
                      "reagieren oder diese sogar automatisch lösen lassen."}
                      textBlock2={<span>NETSTORSYS ermöglicht dem Anwender nicht nur die klassische Lagerverwaltung, sondern unterstützt
                außerdem stationäre Einzelhandelsgeschäfte, indem der Click & Collect-Prozess in den Warenwirtschaftsweg
                eingebunden werden kann.</span>}
                      textBlock3={<span>Dadurch können Kunden Ware online bestellen und danach im Einzelhandelsgeschäft abholen. NETSTORSYS
                verwaltet hierbei alle relevanten Teilprozesse. Dies umfasst Kommissionierung, Versand (interner
                Transport oder per DHL), Transportüberwachung, Annahme und Einlagerung im Einzelhandesgeschäft und die
                Ausgabe der Ware an den Kunden.</span>}
      />
    </>
  )
}

export default Auftragsuebersicht
